<template>
  <div class="section5">
    <!-- <div class="swipe absolute" @mouseenter.stop="toggleTimer = false" @mouseleave.stop="toggleTimer = true">
      <div class="swipe-wrap relative" v-touch:swipe.left="decIndex" v-touch:swipe.right="addIndex">
        <transition-group name="swipe-fade" mode="out-in">
          <div v-for="(slide, i) in slideList" v-show="slideIndex === i" :key="slide.img" :class="`swipe-item absolute`">
            <img :src="slide.img" alt="">
          </div>
        </transition-group>
        <div class="pagination absolute flex-ac" v-if="isPC">
          <div :class="`pagination-dot`" v-for="(slide, index) in slideList" :key="slide.img + '-dot'" @click="goTo(index)"><span :class="`${slideIndex === index ? 'active' : ''}`"></span></div>
        </div>
        <div class="swipe-btns absolute flex-ac flex-jb">
          <img src="./all/prev-btn.png" alt="" class="prev-btn" @click="decIndex">
          <img src="./all/next-btn.png" alt="" class="next-btn" @click="addIndex">
        </div>
      </div>
    </div> v-if="viewIndex === 5" -->
<svg version="1.1" id="圖層_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 984 1070" class="svg2" style="enable-background:new 0 0 984 1070;" xml:space="preserve">

<g>
	
		<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-467.3243" y1="24.5018" x2="-465.8236" y2="24.5018" gradientTransform="matrix(141.9227 -141.9227 141.9227 141.9227 63342.6328 -68890.7188)">
		<stop  offset="0" style="stop-color:#C3D600"/>
		<stop  offset="1" style="stop-color:#008CCA"/>
	</linearGradient>
	<polygon class="st1" points="689,1103.5 725.9,1091.1 492,504.3 492,504.3 	"/>
	
		<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-467.4428" y1="24.1233" x2="-465.9151" y2="24.1233" gradientTransform="matrix(271.5633 -271.5633 271.5633 271.5633 120572.1641 -132476.9688)">
		<stop  offset="0" style="stop-color:#C3D600"/>
		<stop  offset="1" style="stop-color:#008CCA"/>
	</linearGradient>
	<polygon class="st2" points="258.1,1089.6 294.4,1103.7 492,504.3 	"/>
</g>
<g>
	<g>
		<defs>
			<path id="SVGID_3_" d="M492,106.4c-219.9,0-398.1,178.2-398.1,398.1S272.1,902.6,492,902.6s398.1-178.2,398.1-398.1
				S711.9,106.4,492,106.4z M871.2,426.2l-118.8,24.5c-5.6-28-15.8-54.8-30.3-80.1c-14.3-24.8-31.9-46.6-52.7-65.3l80-89.9
				C810.3,269.8,854,343.1,871.2,426.2z M234.7,215.4l80.1,90.1c-43.1,38.7-71.7,90.1-82.9,145.4l-119.1-24.6
				C130,343.1,173.7,269.8,234.7,215.4z M370.2,872l38.2-115.4c53.4,17.8,112.1,18.4,167.1,0.1L613.8,872
				c-38.3,12.7-79.2,19.6-121.8,19.7C449.5,891.7,408.5,884.8,370.2,872z M832.7,688.2L492,504.5l329.4,203.2
				C778,778,712.7,833.4,635,864.3l-44.8-112.9c11.8-4.7,23.4-10.3,34.7-16.9c31.1-18,57.8-41.7,79.3-70.3c3-4,4.1-9,3.1-13.9
				s-4-9.1-8.3-11.6l-110.8-64.2c-12.7,17.8-30.4,32.4-51.9,41.3L492,504.5l37.7,113.7c-25.4,8.8-51.8,8.6-75.7,0.9l38-114.6
				l-44.6,112.3c-21.1-8.2-39.9-22.2-53.6-40.9l-108.5,62.9c-4.3,2.5-7.3,6.7-8.3,11.6c-1,4.9,0.1,9.9,3.1,13.9
				c30.2,40.3,69.9,69.7,113.8,87.2L349,864.4c-77.7-31-143-86.3-186.5-156.6L492,504.5L151.3,688.2
				c-29.6-54.6-46.4-117.2-46.5-183.7c0-19.1,1.4-37.8,4.1-56.1L229.3,466c-6.7,46.8-1.2,95.7,18.4,141.8c1.9,4.6,5.7,8.1,10.4,9.7
				c4.7,1.6,9.9,1.1,14.2-1.4l108.4-62.8c-1.1-2.6-2.2-5.1-3.2-7.8c-7-19.4-8.8-39.5-6-58.6L492,504.5l-119.2-24.6
				c5.3-25.5,18.7-48.9,38.4-66.3l80.9,90.9l-75.4-95.4c9.7-7.6,20.7-13.8,32.8-18.2c9.8-3.6,19.8-5.8,29.7-6.8V256.6
				c0-5-2.1-9.7-5.8-13c-3.2-2.8-7.3-4.4-11.5-4.4c-0.7,0-1.4,0-2.1,0.1c-35.4,4.4-69.2,15.7-100.3,33.8
				c-11.6,6.7-22.5,14.2-32.7,22.3L252,200.9c63.2-50.1,142.4-80.8,228.8-83.4l11.3,387l11.3-387c86.4,2.6,165.6,33.4,228.8,83.4
				l-74.6,94.4c-6.6-5.2-13.4-10.2-20.5-14.8c-33.6-21.9-72.5-36.1-112.4-41.1c-0.7-0.1-1.4-0.1-2.1-0.1c-4.2,0-8.3,1.5-11.5,4.4
				c-3.7,3.3-5.9,8-5.9,13v127.9c22.4,2.8,43.6,11.9,61.1,26.1L492,504.5l79.5-89.3c13.9,12.7,25,28.9,31.9,47.8
				c2.1,5.8,3.7,11.6,4.9,17.5l-116.3,24l117.4-17.2c3.2,22.3-0.1,44.5-8.6,64.5l111,64.3c4.3,2.5,9.5,3,14.2,1.4
				c1.1-0.4,2.2-0.9,3.2-1.4c3.2-1.9,5.8-4.8,7.3-8.3c15.7-37.2,22.8-78,20.7-118.2c-0.4-8-1.2-15.8-2.3-23.6L875,448.4
				c2.7,18.3,4.1,37.1,4.1,56.1C879.1,571,862.3,633.6,832.7,688.2z"/>
		</defs>
		<clipPath id="SVGID_4_">
			<use xlink:href="#SVGID_3_"  style="overflow:visible;"/>
		</clipPath>
		
			<linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="-467.5762" y1="23.973" x2="-465.2092" y2="23.973" gradientTransform="matrix(341.289 0 0 341.289 159685.1875 -7661.1655)">
			<stop  offset="0" style="stop-color:#9CC715"/>
			<stop  offset="1" style="stop-color:#008CCA"/>
		</linearGradient>
		<rect x="58.9" y="92.5" class="st3 an" width="858.3" height="856.1"/>
	</g>
	<g class="an">
		<circle class="st0" cx="492" cy="504.5" r="398.1"/>
		<text transform="matrix(0.9843 0.1763 -0.1763 0.9843 434.2359 731.0489)" class="st4 st5 st6">環</text>
		<text transform="matrix(0.3375 0.9413 -0.9413 0.3375 669.5372 418.6424)" class="st4 st5 st7">康</text>
		<text transform="matrix(0.3371 -0.9415 0.9415 0.3371 299.5635 459.2198)" class="st4 st5 st8">建</text>
		<text transform="matrix(0.6368 -0.771 0.771 0.6368 326.5973 396.8003)" class="st4 st5 st7">築</text>
		<text transform="matrix(0.9908 -0.135 0.135 0.9908 507.3258 739.3367)" class="st4 st5 st9">境</text>
		<text transform="matrix(0.6371 0.7708 -0.7708 0.6371 629.5858 363.5951)" class="st4 st5 st8">健</text>
	</g>
</g>
<g class="an">
<image class="an_r" width="358" height="358" xlink:href="./s5/1.png"  x="-23.70" y="522.80">
</image>
<image class="an_r" width="358" height="358" xlink:href="./s5/2.png"  x="178.75" y="695.20">
</image>
<image class="an_r" width="358" height="358" xlink:href="./s5/3.png"  x="446.5" y="695.20">
</image>
<image class="an_r" width="358" height="358" xlink:href="./s5/4.png"  x="652.75" y="522.80">
</image>
<image class="an_r" width="358" height="358" xlink:href="./s5/5.png"  x="700.10" y="257.70">
</image>
<image class="an_r" width="358" height="358" xlink:href="./s5/6.png"  x="313.00" y="-66.25">
</image>
<image class="an_r" width="358" height="358" xlink:href="./s5/7.png"  x="563.25" y="25.30">
</image>
<image class="an_r" width="358" height="358" xlink:href="./s5/8.png"  x="61.80" y="25.30">
</image>
<image class="an_r" width="358" height="358" xlink:href="./s5/9.png"  x="-74.10" y="257.70">
</image>
<circle class="st0" cx="492" cy="504.5" r="492"/>
</g>
<image width="679" height="679" xlink:href="./s5/0.png"  transform="matrix(0.5 0 0 0.5 322.25 334.75)">
</image>
</svg>
<div class="img"></div>
    <div class="txt txt1" data-aos="fade-up" data-aos-delay="0">
      <h3 class="title title1" data-aos="fade-up" data-aos-delay="400">
        超級玩家．百變樂園
      </h3>
      <div class="hr" data-aos="zoom-in-right" data-aos-delay="600"></div>
      <div class="desc" data-aos="fade-up" data-aos-delay="800">
        在大基地、低建蔽條件下，讓建築融入環境之中，彷彿蓋在公園裡的綠色城堡奇蹟，感受四季變化之美；現代簡約風格、立體框架美學，充滿時尚新穎的天際風采，垂直綠化減碳思維，讓自然、藝術及建築三者融合為一，開闊舒適的超大棟距視野，開啟生活新眼界！
      </div>
    </div>
    <div class="slogan">
      Ocean Resort
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';

.section5 {
  width: 100%;
  height: size(1080);
  position: relative;
}


	.svg2{
  @include img_r_pc(984,0, 851);
  filter: drop-shadow(0 0 7px rgba(0, 0, 0, 0.6));
top: auto;
bottom: 0;
  .st0{fill:none;}
	.st1{fill:url(#SVGID_1_);}
	.st2{fill:url(#SVGID_2_);}
	.st3{clip-path:url(#SVGID_4_);fill:url(#SVGID_5_);}
	.st4{fill:#FFFFFF;}
	.st5{font-family:'Noto Sans tc';}
	.st6{font-size:41.4206px;}
	.st7{font-size:43.062px;}
	.st8{font-size:43.0619px;}
	.st9{font-size:41.4243px;}
  }
  .an{animation: rot 20s linear infinite;
  transform-origin: 496.0173px 504.5241px;}
  .an_r{animation: rot_r 20s linear infinite;
  transform: scale(.5);
  transform-origin:0 0;
  &:nth-child(1){
  transform-origin:155.30px 701.80px
  }
  &:nth-child(2){
  transform-origin:357.75px 874.20px
  }
  &:nth-child(3){
  transform-origin:625.50px 874.20px
  }
  &:nth-child(4){
  transform-origin:831.75px 701.8px
  }
  &:nth-child(5){
  transform-origin:879.10px 436.70px
  }
  &:nth-child(6){
  transform-origin:492.00px 112.75px
  }
  &:nth-child(7){
  transform-origin:742.25px 204.30px
  }
  &:nth-child(8){
  transform-origin:240.80px 204.30px
  }
  &:nth-child(9){
  transform-origin:104.90px 436.70px
  }
  }
@keyframes rot {
  to {
    transform:rotate(360deg);
  }
}
@keyframes rot_r {
  from{
     transform:scale(.5) rotate(360deg);
  }
  to {
    transform:scale(.5) rotate(0deg);
  }
}
.txt {
  @include img_l_pc(557, 170, 1219);
  background-image: transparent;
  padding: 0;
  width:25em;
  font-size:size(23);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.9;
  letter-spacing: normal;
  text-align: justify;
}
.title {
  width: 100%;
  margin: 0 auto;
  font-size:2.391em;
  font-weight: bold;
  color: #fff000;
  line-height: 1.2;
  white-space: nowrap;
  text-align:left;
}

.title2 {
  text-align: right;
}

.hr {
  width: 100%;
  // height: 35px;
  border-top: 1px solid #fff;
  margin: 0.65em 0;
}

.desc {
  // text-shadow: 0 2px 5px rgba(0, 0, 0, 0.44);
  width: 100%;
  margin: 0 auto;
  margin-left: 0;
  font-weight: 500;
  line-height: 2.43;
  color: #ffffff;
  // white-space: nowrap;
}

.txt2 {
  padding: size(20) size(30);
  @include div_l_pc(540, 351, 648, 280);
  background-image: linear-gradient(
    to right,
    rgba(184, 28, 34, 0.9),
    rgba(233, 85, 4, 0.9)
  );
  .title {
    font-size: size(40);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }

  .title1 {
    text-align: left;
  }

  .title2 {
    text-align: right;
  }

  .desc {
    width: 100%;
    font-size: size(20);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }
}

.slogan {
  @include img_r_pc(669, 0, 69);
  top: auto;
  bottom: size(212);
  font-family: Arial;
  font-size: size(100);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: size(2);
  text-align: left;
  color: #ffffff;
  white-space: nowrap;
}

.img {
  position: absolute;
  width:size(1586 * 3);
  height:size(224);
  background: url("./s5/bg.png") 0 100%;
  background-size:auto 100%;
  top: auto;
  left: 0;
  bottom: -1px;
  transform: translateX(size(-1586));
  animation: swing 15s linear infinite reverse;
}
@keyframes swing {
  to {
    transform:translate(0);
  }
}
/* Swipe */
.swipe {
  width: size(1105);
  height: size(745);
  top: size(167);
  right: size(63);
  object-fit: cover;
  // background:#0344;
}

// begin
.swipe-fade-leave-to {
  opacity: 0;
  z-index: 0;
}
// end
.swipe-fade-enter {
  opacity: 0;
  z-index: 1;
}

.swipe-fade-enter-active {
  transition: all 0.5s ease;
}

.swipe-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

// begin
// .swipe-left-leave-to {
//   margin-left: -100vw;
//   z-index: 0;
// }
// // end
// .swipe-left-enter {
//   opacity: 0.5;
//   margin-left: 0;
//   z-index: 1;
// }

// .swipe-left-enter-active {
//   transition: all 0.5s ease;
// }

// .swipe-left-leave-active {
//   transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
// }

.swipe-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.swipe-item {
  width: 100%;
  height: 100%;
  z-index: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slide-name {
    left: 2em;
    bottom: 1.2em;
    color: #fff;
    font-size: size(18);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.03em;
    text-align: left;
    text-shadow: 0 0.3em 1em #000;
  }

  // &:nth-child(1) {
  //   z-index: 1;
  //   // opacity: 1;
  // }

  // &.base {
  //   z-index: 1;
  //   opacity: 1;
  // }
  // &.active {
  //   z-index: 2;
  //   // opacity: 1;
  // }
}

.pagination {
  width: auto;
  bottom: size(18);
  right: size(18);
  left: auto;
  // margin: 0 auto;
  justify-content: center;
}

.pagination-dot {
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
  z-index: 4;

  span {
    display: block;
    width: size(25);
    height: size(25);
    border-radius: size(25);
    // box-shadow: 0 0 0 1px #fff;
    // background: #fff;
    position: relative;
    background-color: #fff;
    transition: all 0.5s;

    &::before {
      content: '';
      width: 60%;
      height: 60%;
      display: block;
      background: #fff;
      border-radius: 20px;
      opacity: 1;
      position: absolute;
      top: 20%;
      // transform: translateY(-50%);
      left: 20%;
      transition: all 0.3s;
      transform-origin: center;
      transform: scale(0);
    }
    &.active {
      box-shadow: none;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background: #fff000;
        border-radius: 20px;
        opacity: 1;
        position: absolute;
        top: 0%;
        // transform: translateY(-50%);
        left: 0%;
        transform: scale(1);
      }
    }
  }
}

.swipe-btns {
  width: 100%;
  height: 100%;
  padding: 0 15px;
  z-index: 3;

  .prev-btn,
  .next-btn {
    width: size(20);
    cursor: pointer;
  }
}

@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .fullscreen {
    height: 100vh;
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .section5 {
    width: 100vw;
    height: sizem(670);
    min-height: auto;
    max-height: initial;
    // background-image: url('./s2/bg.jpg');
    // background-size: 100% 100%;
    // background-position: 0 0;
    // background-attachment: fixed;
    overflow: hidden;
    &::after,
    &::before {
      display: none;
    }
  }

	.svg2{
  @include img_r_m(375,0, 0);
top: auto;
bottom: 0;
  }
  .txt {
    @include img_l_m(320, 25, 25);
    height: auto;
    // filter: drop-shadow(0 0 5px #036);
    display: block;
  }
  .title {
    // @include img_r_m(260, 409, 71);
    font-size: sizem(26);
    font-weight: bold;
    letter-spacing: normal;
    line-height: 2;
    text-align: left;
    span {
      display: block;
      font-size: sizem(20);
      letter-spacing: 0.01em;
    }
  }

  .hr {
    //  @include img_r_m(230, 458, 102);
    height: sizem(1);
    margin: sizem(12) 0;
  }

  .desc {
    // @include img_r_m(292, 474, 40);
    font-size: sizem(15);
    font-weight: normal;
    letter-spacing: normal;
    white-space: normal;
    line-height: 1.73;
    margin: 0 0 1.5em 0;
  }
  .slogan {
    display: none;
  }
}
</style>
<script>
// @ is an alias to /src
import { isPC, isMobile, isTablet } from '@/utils'
import info from '@/info'
// import slider from '@/mixins/slider.js'

export default {
  name: 'section5',

  // mixins: [slider],
  props: ['viewIndex'],

  data() {
    return {
      info,
      isPC,
      isMobile,
      isTablet,
      isDialog: false,
      // slideList: [
      //   {
      //     img: require('./s4/1漁人碼頭、情人橋.jpg'),
      //     name: '漁人碼頭、情人橋',
      //   },
      //   {
      //     img: require('./s4/2美麗新淡海影城.jpg'),
      //     name: '美麗新淡海影城',
      //   },
      //   {
      //     img: require('./s4/3淡水紅毛城.jpg'),
      //     name: '淡水紅毛城',
      //   },
      //   {
      //     img: require('./s4/4淡水老街(中正路).jpg'),
      //     name: '淡水老街(中正路)',
      //   },
      //   {
      //     img: require('./s4/5紅樹林自然保留區.jpg'),
      //     name: '紅樹林自然保留區',
      //   },
      // ],
    }
  },

  methods: {},

  created() {},

  mounted() {},

  computed: {},

  watch: {
    // viewIndex() {
    //   if (this.viewIndex === 5) {
    //     this.slideIndex = 0
    //     console.log(this.slideIndex, 'slideIndex')
    //   }
    // },
  },
}
</script>
