export default [
 // { name: '名軒海樂地', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '', offset: 0 },
  { name: '海灣魅力 ', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '', offset: 0 },
  { name: '超級玩家 ', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '', offset:0 },
  { name: '享樂生活 ', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '', offset: 0 },
  { name: '經典實績 ', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '', offset: 0 },
  { name: '夢幻樂園 ', imgSrc: '', subTitle: '', section: 'section10', svgSrc: '', offset: 0 },
  { name: '預約鑒賞 ', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', offset: 0 },
  // { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: 0, offset: 0 },
  // { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '', mobileOffset: 0, offset: -150 },
]